<template>
  <div>
    <section>
      <LoaderIcon v-if="getAddressConfigurationsLoading" />
      <b-form v-else id="address-setup-form" name="address-setup-form" autocomplete="off" @submit.prevent="onSubmitAddressSetup" novalidate>
        <b-form-row v-for="(config, index) in addressConfigurations.addressConfigs" :key="index">
          <b-col cols="12" sm="10" md="8" lg="6">
            <b-form-group>
              <h4 class="page-sub-header">{{ config.title }}</h4>
              <div class="floating-input-field">
                <b-form-input :id="'address-configuration-' + index" type="url" placeholder=" " v-model="config.value" required></b-form-input>
                <label :for="'address-configuration-' + index">{{ config.title }}</label>
                <b-form-invalid-feedback class="d-block" v-if="!config.value && formSubmitted">{{ config.title }} required.</b-form-invalid-feedback>
                <b-form-invalid-feedback class="d-block" v-if="config.value && !validateUrl(config.value) && formSubmitted"
                  >Invalid url.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-if="addressConfigurations.isCosmosDB && userRole === 'SysAdmin'">
          <b-col cols="12" sm="7" md="6" lg="4">
            <b-form-group>
              <b-form-checkbox v-model="addressConfigurations.isCosmosDB.value" id="cosmo-db-checkbox" name="cosmo-db-checkbox">
                <h4 class="mt-1 page-sub-header">
                  {{ addressConfigurations.isCosmosDB.title }}
                </h4>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-form-row>
        <template v-if="addressConfigurations.isCosmosDB && addressConfigurations.isCosmosDB.value && userRole === 'SysAdmin'">
          <b-form-row v-for="(cosmoDBConfig, cIndex) in addressConfigurations.cosmosDbAddressConfigs" :key="cosmoDBConfig.id">
            <b-col cols="12" sm="10" md="8" lg="6">
              <b-form-group>
                <h4 class="page-sub-header">{{ cosmoDBConfig.title }}</h4>
                <div class="floating-input-field">
                  <b-form-input :id="'cosmo-db-configuration-' + cIndex" type="url" placeholder=" " v-model="cosmoDBConfig.value" required></b-form-input>
                  <label :for="'cosmo-db-configuration-' + cIndex">{{ cosmoDBConfig.title }}</label>
                  <b-form-invalid-feedback class="d-block" v-if="!cosmoDBConfig.value && formSubmitted"
                    >{{ cosmoDBConfig.title }} required.</b-form-invalid-feedback
                  >
                </div>
              </b-form-group>
            </b-col>
          </b-form-row>
        </template>
        <ApplySecurityMethodForm v-if="securityMethodPayload" :formPayload="securityMethodPayload" :formSubmitted="formSubmitted" />
        <b-form-row class="my-3">
          <b-col>
            <b-button type="submit" id="save-address-btn" v-activeBlur variant="primary" :disabled="updateAddressConfigurationsLoading"
              >Save <b-spinner v-if="updateAddressConfigurationsLoading" label="Spinning" small class="ml-2"></b-spinner
            ></b-button>
            <b-button type="button" id="cancel-address-btn" v-activeBlur class="ml-3" @click="resetAddressSetupForm" variant="outline-secondary"
              >Cancel</b-button
            >
          </b-col>
        </b-form-row>
      </b-form>
    </section>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { cloneDeep } from 'lodash'
import { useAxios } from '@/composables/useAxios'
import { useValidateFields } from '@/composables/useValidateFields'
import { ServiceUrls } from '@/utilities/serviceUrls'
import LoaderIcon from '@/assets/svg/loader.svg'
import { tenantSpecificURL } from '@/utilities/utilities'
export default {
  name: 'AddressSetup',
  setup() {
    const { validateUrl, scrollToErrorMessage } = useValidateFields()
    const {
      isLoading: getAddressConfigurationsLoading,
      response: getAddressConfigurationsResponse,
      error: getAddressConfigurationsError,
      callApi: getAddressConfigurationsApi,
    } = useAxios()

    const {
      isLoading: updateAddressConfigurationsLoading,
      response: updateAddressConfigurationsResponse,
      error: updateAddressConfigurationsError,
      callApi: updateAddressConfigurationsApi,
    } = useAxios()

    return {
      validateUrl,
      scrollToErrorMessage,
      getAddressConfigurationsLoading,
      getAddressConfigurationsResponse,
      getAddressConfigurationsError,
      getAddressConfigurationsApi,
      updateAddressConfigurationsLoading,
      updateAddressConfigurationsResponse,
      updateAddressConfigurationsError,
      updateAddressConfigurationsApi,
    }
  },
  data() {
    return {
      formSubmitted: false,
      addressConfigurations: {},
      securityMethodPayload: null,
    }
  },
  components: {
    LoaderIcon,
    ApplySecurityMethodForm: () => import('@/components/common/ApplySecurityMethodForm.vue'),
  },
  computed: {
    ...mapState({
      selectedTenant: (state) => state.common.selectedTenant,
      userRole: (state) => state.common.userRole,
    }),
  },
  mounted() {
    this.getAddressConfigurations()
  },
  methods: {
    showToaster(message, key, type) {
      this.$store.commit('common/setCustomToastData', {
        message: message,
        key: key,
        type: type,
      })
    },
    formatData() {
      const response = cloneDeep(this.getAddressConfigurationsResponse)
      const securityMethodIndex = response.addressConfigs.findIndex((config) => config.title === 'SecurityMethodId')
      if (securityMethodIndex > -1) {
        this.securityMethodPayload = response.addressConfigs[securityMethodIndex]
        this.securityMethodPayload = {
          ...this.securityMethodPayload,
          applySecurityMethod: !!this.securityMethodPayload.value,
          securityMethodId: this.securityMethodPayload.value,
        }
        response.addressConfigs.splice(securityMethodIndex, 1)
      }
      this.addressConfigurations = cloneDeep(response)
    },
    async getAddressConfigurations() {
      const url = this.$route.params.tenantID
        ? `${ServiceUrls.getAddressConfigurations}/${this.$route.params.tenantID}`
        : tenantSpecificURL(ServiceUrls.getAddressConfigurations)
      await this.getAddressConfigurationsApi({ method: 'get', url })
      if (this.getAddressConfigurationsResponse) {
        this.formatData()
      }
      if (this.getAddressConfigurationsError) {
        this.showToaster(false, 'ERROR_GET_ADDRESS_SETUP', 'danger')
      }
    },
    resetAddressSetupForm() {
      this.formatData()
    },
    validateAddressSetupForm() {
      let isCosmoDBValueError = false
      const validUrl = !this.addressConfigurations.addressConfigs.some((config) => !this.validateUrl(config.value))
      if (this.addressConfigurations.isCosmosDB && this.addressConfigurations.isCosmosDB.value) {
        isCosmoDBValueError = this.addressConfigurations.cosmosDbAddressConfigs.some((config) => !config.value)
      }
      const securityMethod = this.securityMethodPayload.applySecurityMethod ? !!this.securityMethodPayload.securityMethodId : true
      this.scrollToErrorMessage()
      return !isCosmoDBValueError && validUrl && securityMethod
    },
    async onSubmitAddressSetup() {
      this.formSubmitted = true
      if (this.validateAddressSetupForm()) {
        if (this.addressConfigurations.isCosmosDB && !this.addressConfigurations.isCosmosDB.value) {
          this.addressConfigurations.cosmosDbAddressConfigs.forEach((data) => (data.value = ''))
        }
        const payload = cloneDeep(this.addressConfigurations)
        this.securityMethodPayload.value = this.securityMethodPayload.securityMethodId || ''
        payload.addressConfigs.push(this.securityMethodPayload)
        await this.updateAddressConfigurationsApi({ method: 'post', url: ServiceUrls.updateAddressConfigurations, data: payload })
        if (this.updateAddressConfigurationsResponse) {
          this.$store.dispatch('common/getAdminTenants')
          this.formSubmitted = false
          this.showToaster(false, 'UPDATE_ADDRESS_SETUP', 'success')
        }
        if (this.updateAddressConfigurationsError) {
          if (this.updateAddressConfigurationsError?.errorMessage) {
            this.showToaster(this.updateAddressConfigurationsError?.errorMessage, false, 'danger')
          } else {
            this.showToaster(false, 'FAILED', 'danger')
          }
        }
      }
    },
  },
  watch: {
    selectedTenant(newValue, oldValue) {
      this.getAddressConfigurations()
    },
  },
}
</script>
<style lang="scss" scoped></style>
