import axios from 'axios'
import { reactive, ref } from 'vue'
import { HTTP } from '@/utilities/http-common'

const cancelKeys = reactive({})

export function useAxios() {

  const isLoading = ref(false)
  const response = ref(null)
  const error = ref(null)
  const isCancel = ref(false)

  const callApi = async (config) => {
    response.value = null
    error.value = null
    isCancel.value = false

    const defaultConfig = { url: '', method: 'get', headers: undefined, data: undefined, isCustomError: null }
    const apiConfig = { ...defaultConfig, ...config }
    if (cancelKeys[apiConfig.url]) {
      cancelKeys[apiConfig.url].abort()
    }
    cancelKeys[apiConfig.url] = new AbortController()

    const options = {
      url: apiConfig.url,
      method: apiConfig.method,
      headers: apiConfig.headers,
      data: apiConfig.data,
      signal: cancelKeys[apiConfig.url].signal,
    }
    try {
      isLoading.value = true
      const res = await HTTP(options)
      if (res?.data?.errorMessage) {
        const error = {
          errorMessage: res.data.errorMessage
        }
        throw error
      } else {
        if (res.data === false) {
          throw res
        } else {
          response.value = res.data
        }
      }
    } catch (err) {
      isCancel.value = !!axios.isCancel(err)
      error.value = err
    } finally {
      isLoading.value = false
    }
  }
  return { isLoading, response, error, isCancel, callApi }
}
